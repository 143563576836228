import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(!_vm.$auth.isAuthenticated)?_c('div',{staticClass:"grey lighten-2"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"grey lighten-2 pa-14",attrs:{"elevation":"0"}},[_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VCardText),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VImg,{attrs:{"src":"/img/lp-logo-sm.png"}})],1),_c(VCol,{attrs:{"cols":"6"}},[(!_vm.$auth.loading)?_c('div',[(!_vm.$auth.isAuthenticated)?_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.login}},[_vm._v("Log in")]):_vm._e(),(_vm.$auth.isAuthenticated)?_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.logout}},[_vm._v("Log out")]):_vm._e()],1):_vm._e()])],1),_c(VRow,[_c('div',{staticClass:"copy-sm"},[_c('p',[_vm._v(" By logging in, you agree to our "),_c('strong',[_vm._v("terms of service ")]),_vm._v("and "),_c('strong',[_vm._v("privacy policy")]),_vm._v(". Copyright © 2022 Leafpoint Inc. ")])])])],1)],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VImg,{attrs:{"src":"/img/login-stock-sm.jpg"}}),_c('div',{staticClass:"over-img"},[_vm._v(" Mobile engagement "),_c('br'),_vm._v("for healthcare consumers ")])],1)],1)],1):_vm._e(),(_vm.$auth.isAuthenticated)?_c('div',{staticClass:"Absolute-Center"},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}),_c('br'),_vm._v("Authenticating... "),(_vm.$auth.user.sub)?_c('div',{staticClass:"auth-progress"},[_vm._v(_vm._s(_vm.lpUser))]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }