<template>
  <div class="home">
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- Log-in page                    -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <div v-if="!$auth.isAuthenticated" class="grey lighten-2">
      <v-row>
        <v-col cols="4">
          <v-card elevation="0" class="grey lighten-2 pa-14">
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>
            <v-card-text></v-card-text>

            <v-row>
              <v-col cols="6">
                <!-- <v-img src="/img/lp-applogo-sm.png"></v-img> -->
                <v-img src="/img/lp-logo-sm.png"></v-img>
              </v-col>

              <v-col cols="6">
                <!-- Check that the SDK client is not currently loading before accessing is methods -->
                <div v-if="!$auth.loading">
                  <!-- show login when not authenticated -->

                  <v-btn
                    block
                    v-if="!$auth.isAuthenticated"
                    color="primary"
                    @click="login"
                    >Log in</v-btn
                  >

                  <!-- show logout when authenticated -->
                  <v-btn
                    block
                    v-if="$auth.isAuthenticated"
                    color="primary"
                    @click="logout"
                    >Log out</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row>
              <div class="copy-sm">
                <p>
                  By logging in, you agree to our
                  <strong>terms of service </strong>and
                  <strong>privacy policy</strong>. Copyright © 2022 Leafpoint
                  Inc.
                </p>
              </div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-img src="/img/login-stock-sm.jpg"></v-img>
          <div class="over-img">
            Mobile engagement
            <br />for healthcare consumers
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- placholder -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
    <div v-if="$auth.isAuthenticated" class="Absolute-Center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br />Authenticating...
      <div v-if="$auth.user.sub" class="auth-progress">{{ lpUser }}</div>
    </div>
    <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      token: null,
      lpUser: {},
      authCheck: true, // check for auth until data is returned and updated
      profileCheck: true, // check for profile until profile is populated
    };
  },
  methods: {
    login: async function () {
      // lots of waits to refresh the screen after send
      await this.auth();
    },
    auth: async function () {
      this.$auth.loginWithRedirect({
        redirect_uri: window.location.origin + "/#/dashboard",
      });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    async readUser(u) {
      if (!this.token) {
        this.token = await this.$auth.getTokenSilently();
      }

      // eg http://localhost:5000/api/users/
      const url = this.$expressPath + "/api/users/" + u;

      console.log(this.$expressPath);
      console.log(url);

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.
          this.lpUser = response.data;
          this.authCheck = false;
        });
    },
  },
  updated() {
    if (this.authCheck) {
      if (this.$auth.user) {
        if (this.$auth.user.sub) {
          const u = this.$auth.user.sub.substr(6, 30);
          this.readUser(u);
        }
      }
    }

    if (this.profileCheck) {
      // if lpUser has data
      if (this.lpUser.customer_name) {
        this.$userProfile.customerID = this.lpUser.customer_id;
        this.$userProfile.customerName = this.lpUser.customer_name;
        this.$userProfile.firstName = this.lpUser.first_name;
        this.$userProfile.lastName = this.lpUser.last_name;
        this.$userProfile.email = this.lpUser.email;
        this.$userProfile.phone = this.lpUser.phone;
        this.$userProfile.shortCodes = this.lpUser.customer_shortcodes;
        this.$userProfile.S3Id = this.lpUser.s3_id;
        this.$userProfile.S3Key = this.lpUser.s3_key;
        this.$userProfile.S3Name = this.lpUser.s3_name;

        // Format User attributes to list
        let v = {};
        for (var key in this.lpUser.custom_attributes) {
          v = this.lpUser.custom_attributes[key];
        }
        let s = [];
        if (Object.keys(v).length > 0) {
          s = v.toString().split(",");
        }
        this.$userProfile.attributes = s;

        this.profileCheck = false;
        this.$router.push("dashboard");
      }
    }
  },
};
</script>

<style scoped>
.copy-sm {
  font-family: sans-serif;
  font-size: 11px;
  text-align: left;
  color: #999;
}

/* Bottom right text */
/* Centered text */
.over-img {
  position: absolute;
  top: 80%;
  right: 20%;
  transform: translate(60%, -50%);
  font-family: sans-serif;
  font-size: 30px;
  color: #fff;
  line-height: 100%;
  padding: 8px;
  background-color: #777;
  opacity: 0.7;
}

.v-progress-circular {
  margin: 1rem;
}

.auth-progress {
  font-family: sans-serif;
  font-size: 6px;
  color: #ddd;
}

.Absolute-Center {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 40%;
  translate: (-50%, -50%);
}
</style>
