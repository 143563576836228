<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="$auth.isAuthenticated && $userProfile.customerID != 'none'"
    >
      <v-list-item class="pa-1 ml-0">
        <v-list-item-content>
          <!-- <v-img src="/img/lp-logo-sm.png" max-width="153" class="ml-2"></v-img> -->
          <v-img src="/img/lp-logo-sm.png" max-width="140" class="ml-2"></v-img>
          <v-list-item-subtitle class="ml-2"
            >Campaign Manager 1.3</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav v-if="$auth.isAuthenticated">
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="$auth.isAuthenticated">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $userProfile.customerName }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- User profile button -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="grey">mdi-account-arrow-left-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(userItem, index) in userItems"
              :key="index"
              @click="userMenuClick(userItem.action)"
            >
              <v-list-item-title>{{ userItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- ----------------------------------------------------------- -->
    <!-- --------------------- User Profile Dialog ----------------- -->
    <!-- ----------------------------------------------------------- -->

    <v-dialog
      v-if="$auth.isAuthenticated"
      v-model="showUserProfile"
      max-width="600px"
      persistant
    >
      <v-card>
        <v-card-text></v-card-text>
        <v-card-text>
          <strong>{{ $userProfile.customerName }}</strong
          ><br />
          <strong>User login: </strong>{{ $auth.user.email }}<br />
          <strong>Name: </strong>{{ $userProfile.firstName }}
          {{ $userProfile.lastName }}<br />
          <strong>Phone: </strong>{{ $userProfile.phone }}<br />
          <div class="caption">(CID:{{ $userProfile.customerID }})</div>
          <!-- {{$userProfile.attributes}} -->
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn color="primary" @click="showUserProfile = false">Done</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    userMenuClick(action) {
      if (action === "logout") {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      }
      if (action === "profile") {
        // console.log(this.$auth.user)
        this.showUserProfile = true;
      }
    },
  },
  data() {
    return {
      drawer: null,
      showUserProfile: false,
      items: [
        { title: "Dashboard", icon: "mdi-monitor-dashboard", to: "/dashboard" },
        {
          title: "Members and Segments",
          icon: "mdi-account-multiple-outline",
          to: "/members",
        },
        {
          title: "Campaigns",
          icon: "mdi-chat-processing-outline",
          to: "/campaigns",
        },

        { title: "Triggers", icon: "mdi-chat-plus-outline", to: "/triggers" },

        { title: "Admin", icon: "mdi-account-cog-outline", to: "/admin" },
      ],
      right: null,

      userItems: [
        { title: "User Profile", action: "profile" },
        { title: "Log Out", action: "logout" },
      ],
    };
  },
};
</script>
