// npm run serve

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";

// Auth0 Authentication
// Import the Auth0 configuration
import allAuth from "../auth_config.json";

let domain = allAuth.domain;
let clientId = allAuth.clientId;
let audience = allAuth.audience;

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.config.productionTip = false;
Vue.prototype.$userAuthorized = false;
Vue.prototype.$userProfile = {
  firstName: "none",
  lastName: "none",
  email: "none",
  phone: "none",
  customerName: "",
  customerID: "none",
  attributes: [],
  shortCodes: [],
};

// Vue.prototype.$expressPath = "http://api.localhost";
// Vue.prototype.$expressPath = process.env.VUE_APP_EXP;

Vue.prototype.$expressPath =
  window.location.protocol + "//" + window.location.hostname;

// Global functions
Vue.mixin({
  methods: {
    // returns friendly file size as string
    gLeafpointFileSize(n) {
      const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      };

      let work;
      let s;

      if (n >= 1000000) {
        work = n / 1000000;
        s = Number(work).toLocaleString("en", options) + "M";
      } else if (n >= 1000) {
        work = n / 1000;
        s = Number(work).toLocaleString("en", options) + "K";
      } else {
        work = n;
        s = Number(work).toLocaleString("en", options) + "B";
      }

      return s;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
