import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/lpTest",
    name: "lpTest",
    // Validates connections to express back-end without authentication
    component: () => import("../views/lpTest.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () =>
      import(/* webpackChunkName: "campaigns" */ "../views/Campaigns.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/triggers",
    name: "triggers",
    component: () =>
      import(/* webpackChunkName: "triggers" */ "../views/Triggers.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/members",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "members" */ "../views/Members.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/addCampaign",
    name: "addCampaign",
    component: () =>
      import(/* webpackChunkName: "addCampaign" */ "../views/addCampaign.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/editCampaign",
    name: "editCampaign",
    component: () =>
      import(
        /* webpackChunkName: "editCampaign" */ "../views/editCampaign.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/addTrigger",
    name: "addTrigger",
    component: () =>
      import(/* webpackChunkName: "addTrigger" */ "../views/addTrigger.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "triggers" */ "../views/Admin.vue"),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
